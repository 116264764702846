@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  font-family: 'Roboto', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  }

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav{
  background-color: #64958f;
  margin-top: 0px;
}

.nav.ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline;
}


.grid-container {
  display: grid;
  padding: 0px;
  padding-top: 1em;
  border: none;
  grid-template-columns: auto auto auto;
}

.grid-item {
  padding: 20px;
  font-size: 30px;
  text-align: center;
  margin: 0.5em;
}

.section{
  border-style: groove;
  background-color: #faf3dd;
  min-height: 100vh;
  width: 100vw;
  border: solid
}

.header{
  background-color: #065c6f;
  color: #faf3dd;
  padding: 1em;
  padding-top: 2em;
  padding-bottom: 0;
  min-height: 20vh;
  align-items: center;
  font-size: 1.5em;
}

.card{
  text-align: left;
  line-height: 3.5em;
  font-size: 0.75em;
  letter-spacing: 0.1em;
}

.body{
  text-align: center;
  letter-spacing: 0.1em;
  margin: 0.5em;
  padding: 0.5em;
}

.blurb{
  height: 20vh;
  text-align: left;
  padding-top: 2em;
  font-size: 1.5em;
  color: #065c6f;
}

.socials{
  min-height:20vh;
  width: 100%;
  justify-content: end;
}

.grid-item{
  padding: 5;
  margin: 0.4em;
}

.flex-container{
  display: flex;
  flex-wrap: wrap;
}

#social-icons{
  justify-content: center;  
}

